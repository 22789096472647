import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import menuStructure from './menuStructure';

Vue.use(Router);

const router = new Router({
	routes: [...routes]
});

const originalPush = router.push;
// Rewrite the router push method to avoid the NavigationDuplicated errors
router.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);

	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

export default router;

export { routes, menuStructure };
