<template>
	<DefaultLayout :has-side-nav="false">
		<section class="Home-searchBox">
			<div class="Home-searchBox-inputWrapper" @click="openSearch()">
				<b-input type="search" readonly="readonly" :placeholder="$t('Search...')" />
			</div>
		</section>
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
	DefaultLayout,
	hpIdTempTokenLoginMixin,
	loginRedirectMixin,
	oldUiAuthCompatibilityMixin
} from '@workflow-solutions/siteflow-section-layout';

export default {
	components: {
		DefaultLayout
	},
	mixins: [
		loginRedirectMixin(mapGetters),
		hpIdTempTokenLoginMixin(mapGetters, mapActions),
		oldUiAuthCompatibilityMixin(mapMutations)
	],
	data() {
		return {
			ready: false
		};
	},
	computed: {
		...mapGetters({
			isAuthenticated: 'user/isAuthenticated'
		}),
		isHPIdTokenProvided() {
			return !!this.$router.currentRoute.query?.token;
		}
	},
	watch: {
		isAuthenticated(value) {
			if (value) this.handleGetVars();
		}
	},
	async created() {
		// Go to the login page if user is not authenticated and hp id token is not provided in the route query
		if (!this.isAuthenticated && !this.isHPIdTokenProvided) {
			this.$router.push({ name: 'login' });
			return;
		}

		await this.handleGetVars();
	},
	methods: {
		...mapActions({
			openSearch: 'search/showSearchModal',
			getVars: 'account/getVars'
		}),
		async handleGetVars() {
			if (!this.isAuthenticated) return;

			await this.getVars().catch(err => console.error('Error loading vars', err));
			this.ready = true;
		}
	}
};
</script>

<style lang="scss" type="text/scss">
.Home-searchBox {
	margin: 50px 100px;

	&-inputWrapper {
		input {
			padding: 10px 15px;
			font-size: 20px;

			&[readonly] {
				background: white;
			}
		}
	}
}
</style>
