import Vue from 'vue';
import moment from 'moment-timezone';
import { BootstrapVue } from 'bootstrap-vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

/* icons */
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* styles */
import '@workflow-solutions/ofs-vue-layout/dist/index.css';
import '@workflow-solutions/siteflow-section-layout/dist/index.css';
import './styles/index.scss';

import siteFlowLayout, {
	FeatureFlags,
	PageService,
	Pusher,
	registerServiceWorker
} from '@workflow-solutions/siteflow-section-layout';

import store, { i18n } from './vuex';
import router, { menuStructure } from './router';
import App from './App';

import { sectionName } from './lib/config';

/**
 * Asynchronous load of the application
 */
(async function run() {
	if (process.env.NODE_ENV === 'production') {
		registerServiceWorker(process.env.BASE_URL, store);
	}

	FeatureFlags.fetchFeatureFlags();
	PageService.setMeta();
	const routingTables = await siteFlowLayout.sectionsRouting.loadRoutes();

	library.add(fas); // add the the whole icon pack
	Vue.component('FontAwesomeIcon', FontAwesomeIcon);

	Vue.config.productionTip = false;
	Vue.use(BootstrapVue);

	Vue.use(siteFlowLayout, { App, moment, store, router, sectionName, routingTables, menuStructure, i18n });
	Vue.use(Pusher);

	// Sentry Error Logging
	// eslint-disable-next-line no-console
	Vue.config.errorHandler = error => console.error(error);

	Sentry.init({
		dsn: window.$config.sentryDsn,
		environment: window.$config.environment,
		integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
		release: process.env.VERSION,
		ignoreErrors: [
			/^(N|n)etwork\s?(E|e)rror/,
			/^Request failed with status code 40(0|1|3)$/,
			/^Failed to fetch$/,
			/^(C|c)ancelled$/,
			/^ResizeObserver loop/,
			/^Loading chunk .+ failed.$/,
			/^NavigationDuplicated/
		]
	});

	/* eslint-disable no-new */
	new Vue({
		i18n,
		el: '#app',
		router,
		store,
		components: { App },
		template: '<App/>'
	});
})();
