import Login from './Login';
import Logout from './Logout';

const routes = [
	{
		path: '/',
		component: { template: '<router-view/>' },
		children: [
			{
				path: 'login',
				name: 'login',
				component: Login
			},
			{
				path: 'production-login',
				name: 'production-login',
				component: Login
			},
			{
				path: 'logout',
				name: 'logout',
				component: Logout
			}
		]
	}
];

export default routes;
