import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createStore } from '@workflow-solutions/siteflow-section-layout';
import { sectionName } from '../lib/config';

Vue.use(VueI18n);

const store = createStore(Vue, {
	persistedStatePaths: {
		// do not remove all the paths in this object
		// otherwise it will save all the store in local storage
		doNotRemove: {}
	},
	sectionName
});

export const i18n = new VueI18n({ silentTranslationWarn: true });
export const $t = str => i18n.t(str);

export default store;
