<template>
	<div id="app" :data-version="version">
		<PrintOSHeader />
		<router-view v-if="ready" />
		<Notification />
		<confirmation-modal />
	</div>
</template>

<script type="text/javascript">
import { mapActions, mapGetters } from 'vuex';
import { Notification } from '@workflow-solutions/ofs-vue-layout';
import { PrintOSHeader } from '@workflow-solutions/siteflow-section-layout';

export default {
	components: {
		Notification,
		PrintOSHeader
	},
	data: () => ({
		isConsentInitialized: false,
		version: process.env.VERSION,
		ready: false
	}),
	computed: {
		...mapGetters({
			lang: 'lang/lang'
		}),
		isHp() {
			return window.$config.isHp;
		}
	},
	async created() {
		await this.loadLanguage({ lang: this.lang, iframeMode: false }).catch(
			err => console.error('Error loading languages', err) // eslint-disable-line no-console
		);

		this.ready = true;
		this.checkCookieConsent();
	},
	methods: {
		...mapActions({
			loadLanguage: 'lang/loadLanguage'
		}),
		checkCookieConsent() {
			if (this.isConsentInitialized || this.isHp) return;

			const script = document.createElement('script');

			script.type = 'text/javascript';
			script.src = 'https://www.hp.com/cma/ng/lib/exceptions/privacy-banner.js';
			script.charset = 'UTF-8';

			const scriptTags = document.getElementsByTagName('script');
			scriptTags[0].parentNode.insertBefore(script, scriptTags[0]);

			this.isConsentInitialized = true;
		}
	}
};
</script>
